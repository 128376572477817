<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Vehicle Running Logs
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          depressed
          outlined
          @click="newItem()"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Running Logs',
              'Overrule'
            )
          "
        >
          <v-icon left> mdi-plus </v-icon> New Log - Overrule
        </v-btn>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Running Logs',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Running Logs',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your running logs</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View running logs of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Running Logs',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all running logs</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="projectUuids"
            :items="projects"
            item-text="code"
            item-value="uuid"
            label="Projects"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="vehicleUuids"
            :items="vehicles"
            item-text="vehicle_no"
            item-value="uuid"
            label="Vehicles"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Staff"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="reviewStatus"
            :items="reviewStatuses"
            label="Status"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="selectedCols"
            :items="allHeaders"
            item-text="text"
            item-value="id"
            label="Columns"
            outlined
            dense
            hide-details
            @change="updateHeadders"
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedCols.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-btn depressed block color="green" dark @click="exportCSV">
            <v-icon class="me-2"> mdi-file-export-outline </v-icon> export csv
          </v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        id="imso-log-table"
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.description="{ item }">
          <v-sheet max-width="300" color="transparent">
            {{ item.description }}
          </v-sheet>
        </template>
        <template v-slot:item.review_status="{ item }">
          <v-chip
            small
            dark
            :color="
              item.review_status == 'APPROVED'
                ? 'green'
                : item.review_status == 'REJECTED'
                ? 'error'
                : 'amber darken-2'
            "
            >{{
              item.review_status == "APPROVED"
                ? "Approved"
                : item.review_status == "REJECTED"
                ? "Rejected"
                : "Pending"
            }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.edit_status == 'DISABLED'"
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
          <v-badge
            bordered
            :color="
              item.edit_status == 'REQUEST_WAITING'
                ? 'orange'
                : item.edit_status == 'REQUEST_REJECTED'
                ? 'error'
                : 'green'
            "
            dot
            overlap
            v-else
          >
            <v-btn
              depressed
              small
              color="primary"
              class="mx-0 px-0 mt-1"
              dark
              @click="viewItem(item)"
            >
              more
            </v-btn>
          </v-badge>
        </template>
        <template v-slot:item.dispatch_km="{ item }">
          {{ item.dispatch_km }}
          <v-chip
            color="orange"
            dark
            x-small
            v-if="
              item.dispatch_km != item.prev_return_km &&
              vehicleUuids.length == 1
            "
            >non-sequential</v-chip
          >
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
      </v-data-table>
      <v-card-actions>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="orange" class="me-3"></v-badge> Edit Access
          Requested
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="red" class="me-3"></v-badge> Edit Access Rejected
        </v-sheet>
        <v-sheet
          class="d-flex align-center text-caption px-2"
          color="transparent"
        >
          <v-badge dot color="green" class="me-3"></v-badge> Edit Access Granted
        </v-sheet>
      </v-card-actions>
      <v-card-actions class="d-flex align-center text-caption px-2">
        To identify any gaps in meter readings, please select a specific
        vehicle. You can apply additional filters as needed.
      </v-card-actions>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAllVehicleRunning } from "@/services/logsService";
import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
} from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    vehicleUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    projectUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    reviewStatus: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 2,
    dataLoading: false,
    loading: false,
    selectedCols: [1, 2, 3, 4, 5, 8, 9, 10, 15, 16],
    allHeaders: [
      {
        id: 1,
        text: "Log Id",
        value: "id",
        sortable: false,
      },
      {
        id: 2,
        text: "Date",
        value: "date",
        sortable: false,
      },
      {
        id: 3,
        text: "Vehicle",
        value: "vehicles.vehicle_no",
        sortable: false,
      },
      {
        id: 4,
        text: "Project",
        value: "projects.code",
        sortable: false,
      },
      {
        id: 5,
        text: "Driver",
        value: "staff.first_name",
        sortable: false,
      },
      {
        id: 6,
        text: "Dispatch Time",
        value: "dispatch_time",
        sortable: false,
      },
      {
        id: 7,
        text: "Return Time",
        value: "return_time",
        sortable: false,
      },
      {
        id: 8,
        text: "Dispatch (km)",
        value: "dispatch_km",
        sortable: false,
      },
      {
        id: 9,
        text: "Return (km)",
        value: "return_km",
        sortable: false,
      },
      {
        id: 10,
        text: "Total (km)",
        value: "total_km",
        sortable: false,
      },
      {
        id: 11,
        text: "Description",
        value: "description",
        sortable: false,
      },
      {
        id: 12,
        text: "Image Meta",
        value: "image_meta",
        sortable: false,
      },
      {
        id: 13,
        text: "Created at",
        value: "created_at",
        sortable: false,
      },
      {
        id: 14,
        text: "Modified at",
        value: "modified_at",
        sortable: false,
      },
      {
        id: 15,
        text: "Status",
        value: "review_status",
        sortable: false,
      },
      { id: 16, text: "Action", value: "actions", sortable: false },
    ],
    headers: [
      {
        text: "Log Id",
        value: "id",
        sortable: false,
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
      },
      { text: "Vehicle", value: "vehicles.vehicle_no", sortable: false },
      { text: "Project", value: "projects.code", sortable: false },
      { text: "Driver", value: "staff.first_name", sortable: false },
      { text: "Dispatch (km)", value: "dispatch_km", sortable: false },
      { text: "Return (km)", value: "return_km", sortable: false },
      { text: "Total (km)", value: "total_km", sortable: false },
      { text: "Status", value: "review_status", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    vehicleUuids: [],
    staffUuids: [],
    projectUuids: [],
    vehicles: [],
    projects: [],
    staff: [],
    reviewStatus: [],
    reviewStatuses: ["APPROVED", "REJECTED", "PENDING"],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Read All"
      )
    ) {
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.projects = await fetchAndStoreProjectsData();
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      await this.fetchLogs();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewVehicleRunning",
        params: { uuid: item.uuid },
        query: { bc: item.id, mylog: "false" },
      });
    },
    newItem() {
      this.$router.push({
        name: "PageNewVehicleRunning",
        query: { overrule: "true" },
      });
    },
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyVehicleRunningList",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubVehicleRunningList",
        });
      }
    },
    async fetchLogs() {
      this.loading = true;
      this.items = await fetchAllVehicleRunning(
        this.page,
        this.limit,
        this.startDate ? this.startDate : "",
        this.endDate ? this.endDate : "",
        JSON.stringify(this.vehicleUuids),
        JSON.stringify(this.staffUuids),
        JSON.stringify(this.projectUuids),
        JSON.stringify(this.reviewStatus)
      );
      if (this.vehicleUuids.length == 1) {
        this.items.data = this.$_.orderBy(
          this.items.data,
          ["dispatch_km"],
          ["asc"]
        );
        this.items.data.forEach((log, i) => {
          if (i == 0) {
            log["prev_return_km"] = log.dispatch_km;
          } else {
            log["prev_return_km"] = this.items.data[i - 1].return_km;
          }
        });
      }
      this.loading = false;
    },
    updateHeadders() {
      this.headers = [];
      this.selectedCols.forEach((id) => {
        this.headers.push(this.allHeaders.find((element) => element.id == id));
      });
      this.headers.sort((a, b) => a.id - b.id);
      // this.headers.push({ text: "Action", value: "actions", sortable: false });
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Vehicle Running Log";

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
  },
};
</script>